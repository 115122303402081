import * as React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const guru = `<div id="rest_circ" onclick="if(event.target.nodeName.toLowerCase() != 'a') {window.open(this.querySelector('.circ_top_title').href);return 0;}"><div class="circ_cont"><div class="circ_img" style="background: url('https://awards.infcdn.net/img/star_red.svg') no-repeat center">&nbsp;</div><a href="https://restaurantguru.com" target="_blank" class="circ_top_title">Restaurant Guru 2023</a><span class="">Das beste Sushi</span><a href="https://de.restaurantguru.com/Kyoto-Paderborn" class="circ_bot_title " target="_blank">Kyoto Sushi Bar</a></div></div>`;

  return (
    <>
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
        <div className="pb-20 md:px-0 md:pb-0">
            <main>{children}</main>
        </div>
        <footer className="relative pt-44 md:pt-0">
            <div className="bg absolute inset-0 md:hidden">
                <StaticImage
                    src="../images/footer.png"
                    width={375}
                    quality={80}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Kyoto Hintergrund"
                    className="block"
                    style={{display: 'block'}}
                />
            </div>
            <div className="flex flex-col md:flex-row justify-between md:items-end text-white bg-black p-5 relative z-10 text-sm">
                <div>
                    © {new Date().getFullYear()} Kyoto<br/>
                    Ihn. Andreas Spreier<br/>
                    Heiersstraße 37 &bull; 33098 Paderborn<br/>
                    <a href="tel:+4952514145982">05251 4145982</a> &bull; <a href="mailto:info@vaan-lounge.de">info@vaan-lounge.de</a>
                    <div className="mt-2">
                      <Link to="/impressum">Impressum</Link> &bull; <Link to="/datenschutz">Datenschutz</Link>
                    </div> 
                </div>
                <div className="mt-2 md:mt-0 hidden md:block">
                    <div dangerouslySetInnerHTML={{ __html: guru }} />
                </div>
            </div>
        </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
